import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, List, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import axios from 'axios';
import MUIDataTable, { MUIDataTableCustomHeadRenderer, SelectableRows } from 'mui-datatables';
import { useState } from 'react';
import Collapsible from 'react-collapsible';
import { CartItem } from '../../models/cart';
import { Container } from '../../models/container';
import { Product } from '../../models/product';
import { getMuiTheme, noSelectHeaderTemplate } from '../DataTable/TableUtils';
import './ListContainers.css';

type ProductsDataType = {
	orderQuantity: number;
	orderDetailsId: string;
	product: Product;
};

const ListContainers = (props: any) => {
	const [openProduct, setOpenProduct] = useState<ProductsDataType>();
	const productsData: [ProductsDataType] = props.productsData;
	const orderDetails = props.orderDetails;
	/** return all containers with all its data */
	const containerByProductMap = props.containerByProductMap;

	// const [bookedContainerArrayByProductId, setBookedContainerArrayByProductId] = useState<
	// 	Map<number, Container[]>
	// >(props.bookedContainerArrayByProductId);

	/** return the booked container   */
	const [selectedContainersByProductId, setSelectedContainersByProductId] = useState<
		Map<number, Container[]>
	>(props.bookedContainerArrayByProductId);

	const maxQuantity = openProduct?.orderQuantity ?? 1;

	const handleOpen = (i: number) => {
		setOpenProduct(productsData[i]);
	};

	const isOpen = (index: number) => {
		return productsData[index].product.id === openProduct?.product?.id;
	};

	const getAllIndexes = (containerArray: Container[], selectedContainers: any[]) => {
		// Use Array.filter to select elements where status is "booked"
		const selectedBookedContainers = selectedContainers?.filter((container) => {
			return container.status === 'booked' || container.status === 'available';
		});
		const indexes = [];
		let i,
			j = 0;
		for (i = 0; i < containerArray.length; i++) {
			for (j = 0; j < selectedBookedContainers.length; j++) {
				if (selectedBookedContainers[j].id === containerArray[i].id) {
					indexes.push(i);
				}
			}
		}
		return indexes;
	};

	const columnDefs = [
		{
			label: 'Container Number',
			name: 'containerNumber',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '', '5px')
			}
		},
		{
			label: 'Depot',
			name: 'depot',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[1]['code'] ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '', '5px')
			}
		},
		{
			label: 'Age',
			name: 'age',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '', '5px')
			}
		}
	];

	const handleSaveManageOrder = () => {
		const body: any = [];
		[...selectedContainersByProductId.keys()].map((key) => {
			const selectedContainersProductId =
				Array.from(selectedContainersByProductId.get(key) ?? [])[0]?.productId ?? -1;
			Array.from(selectedContainersByProductId.get(key) ?? [])?.forEach(
				(container: Container) => {
					body.push({
						containerNumber: container.containerNumber
					});
				}
			);
		});
		axios
			.put(
				'/api/container/orders/' + props.orderId + '/containers',
				body
			)
			.then((response) => {
				props.setMessageType('success');
				props.setMessageText('Save Successful');
				props.setOpen(true);
				props.setIsSaved(true);
			})
			.catch((err) => {
				props.setMessageType('error');
				props.setMessageText(err.response.data.description);
				props.setOpen(true);
				props.setIsSaved(true);
		});
	};
	return (
		<div className="listContainers">
			{/* Title */}
			<div className="listContainers-top">
				<p>Choose Containers</p>
			</div>

			{/* Table */}
			<div className="listContainers-middle">
				{productsData &&
					productsData.map((product, index: number) => {
						/** mui datatable options */
						const options = {
							selectableRows: 'multiple' as SelectableRows,
							selectableRowsHeader: false,
							isRowSelectable: (dataIndex: any, selectedRows: any) => {
								if (
									selectedRows.data.length >= maxQuantity &&
									selectedRows.data.filter((d: any) => d.dataIndex === dataIndex)
										.length === 0
								)
									return false;
								return true;
							},
							onRowSelectionChange(
								currentRowsSelected: any[],
								allRowsSelected: any[],
								rowsSelected?: any[]
							) {
								const openProductId = openProduct?.product.id ?? -1;
								const productID = product?.product.id ?? openProductId;
								setSelectedContainersByProductId((prev) => {
									return prev.set(productID, props.uniqueById([], 'id'));
								});
								rowsSelected?.map((row) => {
									const container =
										containerByProductMap.get(productID)[row] ?? undefined;

									setSelectedContainersByProductId((prev) => {
										return prev.set(
											productID,
											props.uniqueById(
												[...(prev.get(productID) ?? []), container],
												'id'
											)
										);
									});
								});
							},
							rowsSelected: getAllIndexes(
								Array.from(
									containerByProductMap?.get(product.product.id ?? -1) ?? []
								),
								Array.from(
									selectedContainersByProductId?.get(product.product.id ?? -1) ??
										[]
								)
							) as any,
							rowsPerPageOptions: [],
							serverSide: true,
							viewColumns: false,
							print: false,
							download: false,
							search: false,
							filter: false,
							customToolbarSelect: (
								selectedRows: {},
								displayData: {},
								setSelectedRows: (rows: number[]) => void
							) => {
								return (
									<div></div>
								);
							},
						};
						const priceAfterCurrencyChange = product?.product?.prices?.find(
							(price: any) => price.currencyType === 'MAIN'
						);

						return (
							<Collapsible
								open={isOpen(index)}
								trigger={
									<List
										key={index}
										onClick={() => {
											handleOpen(index);
										}}
										component="div"
										disablePadding
										className="listContainer-listHeader"
									>
										<ListItemText className="listContainer-listHeader-item">
											Category: {product?.product?.category}
										</ListItemText>
										<ListItemText className="listContainer-listHeader-item">
											Condition: {product?.product?.condition?.toUpperCase()}
										</ListItemText>
										<ListItemText className="listContainer-listHeader-item">
											Location:{' '}
											{product?.product?.city +
												', ' +
												product?.product?.country?.toUpperCase()}
										</ListItemText>
										<ListItemText className="listContainer-listHeader-item">
											Price:{' '}
											{(priceAfterCurrencyChange?.currency + ' ' ?? '') +
												priceAfterCurrencyChange?.price}
										</ListItemText>
										<ListItemText className="listContainer-listHeader-item">
											Qty: {product?.orderQuantity}
										</ListItemText>
										<ListItemIcon>
											{productsData[index].product.id ===
											openProduct?.product?.id ? (
												<KeyboardArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)}
										</ListItemIcon>
									</List>
								}
								key={index}
							>
								{containerByProductMap && (
									<List component="div">
										<ThemeProvider theme={getMuiTheme()}>
											<MUIDataTable
												title={''}
												data={containerByProductMap.get(product.product.id)}
												columns={columnDefs}
												options={{
													...options,
													pagination: false
												}}
											/>
										</ThemeProvider>
									</List>
								)}
							</Collapsible>
						);
					})}
			</div>

			{/* Button */}
			<div className="listContainers-bottom">
				<Button
					variant="contained"
					className="listContainers-bottom-button"
					onClick={handleSaveManageOrder}
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default ListContainers;
