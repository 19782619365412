import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { countryList } from '../../../../shared/constants/countryList';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Alert,
	AlertTitle,
	TextField,
	Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import AlertCmpt from '../../../buyer/Util/AlertCmpt';
import './AgentEditAcModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConditions } from '../../../../redux/features/condition';
import { Container } from '../../../../shared/models/container';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const AgentEditMultiAcModal = (props: any) => {
	let userInfo: any = window.sessionStorage.getItem('user');
	userInfo = JSON.parse(userInfo);
	const {
		open,
		setOpen,
		selected,
		firstEditContainer,
		setFirstEditContainer,
		setAwaitingConfirmationData,
		setCurrentPage,
		param,
		getData,
		setSelectedRows,
		setSelectedItems
	} = props;

	const [progressBar, setProgressBar] = useState(false);
	const [priceListData, setPriceListData] = useState<{ price: number }>({ price: 0 });
	const [openError, setOpenError] = useState(false);
	/** when confirming a selected record if price is null and condition is none set this to true */
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	
	const dispatch = useDispatch();
	const conditionsState = useSelector((state: any) => state.conditions);

	const conditionsOptions = conditionsState.conditions.map((item:any) => ({
		label: item.condition,
		value: item.condition
	}));
	const maxCharacters = 500;
	const [comments, setComments] = useState('');

	useEffect(() => {
		dispatch(fetchConditions());
	},[]);

	// Function to update the combined address when any of the address fields change
	const handleAddressChange = () => {
		if (!selected || selected.length === 0) {
		  return '';
		}
	  
		// Use a Set to keep track of unique addresses
		const addressSet = new Set<string>();
	  
		selected.forEach((item: Container) => {
		  const { depot } = item || {};
	  
		  if (!depot) return;
	  
		  let combinedAddress = '';
	  
		  if (depot.address1) combinedAddress += `${depot.address1}\n`;
		  if (depot.address2) combinedAddress += `${depot.address2}\n`;
		  if (depot.address3) combinedAddress += `${depot.address3}\n`;
		  if (depot.postCode) combinedAddress += `${depot.postCode}\n`;
		  if (depot.townName) combinedAddress += `${depot.townName}\n`;
		  if (depot.countryName) combinedAddress += `${depot.countryName}\n`;
		  if (depot.districtName) combinedAddress += `${depot.districtName}\n`;
		  if (depot.name) combinedAddress += `${depot.name}\n`;
	  
		  // Add the combined address to the Set
		  addressSet.add(combinedAddress.trim()); // trim to avoid trailing newlines
		});
	  
		// Join all unique addresses with a newline between them
		return Array.from(addressSet).join('\n\n'); // double newline for separation
	  };

	const refreshContainer = () => {
		getData(1);
	};

	useEffect(() => {
		getPriceList();
	}, [firstEditContainer?.condition]);

	/** get data for table with pagination */
	const getPriceList = () => {
		axios
			.get(
				`/api/container/priceLists/prices?condition=${firstEditContainer?.condition}&containerNumber=${selected[0]?.containerNumber}`
			)
			.then((response) => {
				setPriceListData(response.data);
			})
			.catch(() => {
				setPriceListData({ price: 0 });
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Utility function to check if all selected containers have the same value for a given key
	const getCommonValue = (key: string) => {
		const firstValue = selected[0]?.[key];
		return selected.every((container: any) => container[key] === firstValue) ? firstValue : '';
	};

	useEffect(() => {
		if (open) {
			const defaultCondition = getCommonValue('condition') || 'none';
			const defaultPrice = getCommonValue('price');
			const defaultComments = getCommonValue('comments');

			setFirstEditContainer((prev: any) => ({
				...prev,
				condition: defaultCondition,
				price: defaultPrice,
				comments: defaultComments
			}));
			setComments(defaultComments || '');
		} else {
			// Reset state when modal closes
			setFirstEditContainer(null);
			setComments('');
		}
	}, [open, selected]);

	const onInputChange = (e: any, name: string) => {
		const val = (e.target && e.target.value) || '';
	
		let dataUpdated = { ...firstEditContainer };
	
		switch (name) {
			case 'condition':
				dataUpdated.condition = val;
				break;
			case 'price':
				dataUpdated.price = val === '' ? 0 : Number(val);
				break;
			case 'comments':
				dataUpdated.comments = val;
				break;
			default:
				break;
		}
	
		setFirstEditContainer(dataUpdated);
	};
	
	const paramsContainers = {
		agentUid: userInfo.ccgId,
		status: 'awaiting confirmation',
		price: firstEditContainer?.price || 0,
		condition: firstEditContainer?.condition || 'none',
		comments: firstEditContainer?.comments || '',
		containers: selected.map((item: any) => ({
			containerNumber: item.containerNumber
		}))
	};

	const handleSaveModal = () => {
		const headers = { range: '0-9' };
		setProgressBar(true);
		axios
			.put('/api/container/laraContainers', paramsContainers)
			.then((response) => {
				if (response.status === 200) {
					if (userInfo === undefined || userInfo === null) return;
					const userLocation = userInfo.location?.split(',')[0].toLowerCase();

					const userLocCode = countryList.find(
						(country: any) => country.label.toLowerCase() === userLocation
					) ?? { label: '', value: '' };

					let tempParam = param;
					if (userInfo?.role !== 'admin') {
						tempParam = {
							...param,
							country: userLocCode.value ?? ''
						};
					}
					if (userInfo?.role === 'admin' || (tempParam['country'] && tempParam['country'] !== '')) {
					axios
						.get('/api/container/laraContainers', {
							params: tempParam,
							headers,
							paramsSerializer: {
								serialize: (params) => {
									return qs.stringify(params, {
										arrayFormat: 'comma',
										encode: false
									});
								}
							}
						})
						.then((response) => {
							setAwaitingConfirmationData(response?.data);
							setProgressBar(false);
							setOpen(false);
							setSelectedRows(new Map<number, number[]>([]));
							setSelectedItems([]);
						})
						.catch(() => {
							setAwaitingConfirmationData([]);
							setProgressBar(false);
							setOpen(false);
							setSelectedRows(new Map<number, number[]>([]));
							setSelectedItems([]);
						});
					}
				}
				refreshContainer();
				setCurrentPage(1);
			})
			.catch((error) => {
				setProgressBar(false);
				setMessageType('error');
				setMessageText(error.response.data.description);
				setOpenError(true);
			})
	};

	return (
		<div>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>Manage</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							disabled
							id="containerNo"
							label="ID"
							value={selected?.map((item: Container) => item.containerNumber).join(', ')}
							multiline
      						rows={Math.ceil((selected?.map((item: Container) => item.containerNumber).join(', ')).length / 90)}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="demo-simple-select"
							value={firstEditContainer?.category}
							label="Category"
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							required
							disabled
							id="demo-simple-select"
							value={firstEditContainer?.size}
							label="Size"
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="demo-simple-select"
							value={firstEditContainer?.containerType}
							label="Type"
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="containerDepot"
							label="Depot"
							value={Array.from(new Set(selected?.map((item: Container) => item?.depot?.code))).join(', ')}
							multiline
							rows={Math.ceil(Array.from(new Set(selected?.map((item: Container) => item?.depot?.code))).join(', ').length / 90)}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							id="containerDepotAddress"
							label="Depot Address"
							multiline
							rows={7}
							disabled
							value={handleAddressChange()}
							style={{ marginBottom: '15px' }}
						/>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Condition *</InputLabel>
							<Select
								required
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={firstEditContainer?.condition || 'none'}
								label="Condition"
								onChange={(e) => onInputChange(e, 'condition')}
								style={{ marginBottom: '15px' }}
							>
								{conditionsOptions.map((condition: any) => {
									return <MenuItem value={condition.value}>{condition.label}</MenuItem>
								})}
							</Select>
						</FormControl>
						<TextField
							required
							id="price"
							disabled={firstEditContainer?.condition === 'none'}
							label={
								firstEditContainer?.condition === 'none'
									? 'Please Add a condition'
									: 'Suggested Price: ' + priceListData.price
							}
							value={Number(firstEditContainer?.price) || 0}
							onChange={(e) => onInputChange(e, 'price')}
							style={{ marginBottom: '15px' }}
							inputProps={{ maxLength: 4 }}
						/>
						<FormControl fullWidth>
							<TextField
								id="comments"
								label="Comments"
								multiline
								rows={4}
								value={comments}
								onChange={(e) => {
								if (e.target.value.length <= maxCharacters) {
									setComments(e.target.value);
									onInputChange(e, 'comments');
								}
								}}
							/>
							<Typography color={comments.length > maxCharacters ? 'error' : 'textSecondary'}
								style={{ marginBottom: '15px', fontSize: '13px' }}>
								{comments.length}/{maxCharacters} characters
							</Typography>
						</FormControl>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => {
									setOpen(false); 
									setProgressBar(false)
								}}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default AgentEditMultiAcModal;
