import CloseIcon from '@mui/icons-material/Close';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { debounce } from 'lodash';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Modal,
	SelectChangeEvent,
	ThemeProvider
} from '@mui/material';
import axios from 'axios';
import MUIDataTable, {
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import {
	getMuiTheme,
	inputTextHeaderTemplate,
	multipleInputHeaderTemplate,
	noSelectHeaderTemplate,
	rangeHeaderTemplate,
	selectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { countryList } from '../../../shared/constants/countryList';
import { Container } from '../../../shared/models/container';
import colors from '../../../shared/styles/_variables.module.scss';
import AgentLayout from '../Util/AgentLayout';
import './AgentACContainers.css';
import AgentEditAcModal from './agent-edit-modal/AgentEditAcModal';
import { useSelectedItems } from '../Util/SelectedItemsContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../redux/features/category';
import { fetchConditions } from '../../../redux/features/condition';
import { fetchTypes } from '../../../redux/features/type';
import { fetchSizes } from '../../../redux/features/size';
import { setMenuActive } from '../../../redux/features/menuActive';
import AlertCmpt from '../../buyer/Util/AlertCmpt';
import e from 'express';
import AgentEditMultiAcModal from './agent-edit-modal/AgentEditMultiAcModal';


const AgentACContainers = () => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userInfo: any = window.sessionStorage.getItem('user');
	userInfo = JSON.parse(userInfo);
	/** price range for filtering */
	const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
	const [agentCountry, setAgentCountry] = useState<{ label: string; value: string }>({
		label: '',
		value: ''
	});
	const paramInitialState = {
		price: [0, 10000],
		status: 'awaiting confirmation',
		sortBy: 'DEPOT',
		sortOrder: 'asc',
		country: agentCountry.value
	};

	type OrderSortingMap = {
		[key: string]: string;
	};

	const orderSortingMap: OrderSortingMap = {
		containerNumber: 'CONTAINER_NUMBER',
		depot: 'DEPOT',
		containerType: 'TYPE',
		condition: 'CONDITION',
		size: 'SIZE',
		price: 'PRICE',
		category: 'CATEGORY'
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Depot Code',
			name: 'depot',
			options: {
				display: false
			}
		},
		{
			label: 'Container Type Size Display',
			name: 'containerType',
			options: {
				display: false
			}
		},
		{
			label: 'Comment',
			name: 'comments',
			options: {
				display: false
			}
		},
		{
			label: 'Images',
			name: 'image',
			options: {
				customBodyRenderLite: (index: any) => {
					let imageSource = '/media/container-logo.png';
					const containerS3Image = containerImage?.at(index);
					if(containerS3Image !== null){
						return <img src={containerS3Image} />
					}
				
						return <img src={imageSource} />;
				},
				/* customBodyRender: () => {
					return <img src="/media/container-logo.png" />;
				}, */
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'ID',
			name: 'containerNumber',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleInputTextChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Depot',
			name: 'depot',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[0]['code'] ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleInputTextChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Category',
			name: 'category',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						categoryOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Condition',
			name: 'condition',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						conditionOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},

		{
			label: 'Size',
			name: 'size',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[8] + 'ft' ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						sizeOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Price',
			name: 'price',
			options: {
			  customBodyRenderLite: (dataIndex: any) => {
				const container = awaitingConfirmationData?.at(dataIndex);
				return (container?.currency + ' ' ?? '') + container?.price;
			  },
			  customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
				multipleInputHeaderTemplate(
					columnMeta.label || '',
					columnMeta.name,
					param,
					handleInputTextChange,
					sorting,
					setSorting,
					orderSortingMap[columnMeta.name]
				)
			  ),
			},
		},

		{
			label: 'Type',
			name: 'containerType',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[1] ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						typeOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Manage',
			name: 'edit',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return <EditOutlined onClick={() => onEditRow(tableMeta)} />;
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	/** for filtering */
	const [param, setParam] = useState<any>(paramInitialState);
	/** for sorting */
	const [sorting, setSorting] = useState({ sortBy: 'DEPOT', sortOrder: 'asc' });
	/** table data */
	const [awaitingConfirmationData, setAwaitingConfirmationData] = useState<any[]>([]);
	/** to show/ not show progress bar */
	const [isLoading, setIsLoading] = useState(true);
	/** to open/close pop up */
	const [open, setOpen] = useState<boolean>(false);
	const [openEdit, setOpenEdit] = useState<boolean>(false);
	/** mark unavailable records to be send to backend when selecting records and clicking mark unavailable */
	const [markUnavailableData, setMarkUnavailableData] = useState<any[]>([]);
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(10);
	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);
	/** when confirming a selected record if price is null and condition is none set this to true */
	const [confirmError, setConfirmError] = useState<boolean>(false);
	/** error pop up */
	const [openError, setOpenError] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');

	/** disable/enable confirm button */
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	/** disable/enable edit selected button */
	const [buttonEditDisabled, setButtonEditDisabled] = useState<boolean>(true);
	/** progress circular bar when editing a record and waiting for success */
	const [progressBar, setProgressBar] = useState(false);
	/** get row data by id */
	const [rowDataById, setRowDataById] = useState<Container>();
	const { selectedItems, setSelectedItems } = useSelectedItems();
	const [containersDeselected, setContainersDeselected] = useState<any>([]);

	const [containerImage, setContainerImage] = useState<any>([]);
	const [firstEditContainer, setFirstEditContainer] = useState<Container>();

	type SelectedRowsState = Map<number, number[]>;
	const [selectedRows, setSelectedRows] = useState<SelectedRowsState>(new Map());

	const dispatch = useDispatch();
	dispatch(setMenuActive('two'));
	const conditionsState = useSelector((state: any) => state.conditions);
	const categoriesState = useSelector((state: any) => state.categories);
	const typesState = useSelector((state: any) => state.types);
	const sizesState = useSelector((state: any) => state.sizes);

	const conditionOptions = conditionsState.conditions.map((item:any) => ({
		label: item.condition,
		value: item.condition
	}));

	const categoryOptions = categoriesState.categories.map((item:any) => ({
		label: item.category,
		value: item.category
	}));
	
	const typeOptions = typesState.types.map((item:any) => ({
		label: item.containerType,
		value: item.containerType
	}));
	
	const sizeOptions = sizesState.sizes.map((item:any) => ({
		label: item.size + 'ft',
		value: item.size
	}));
	useEffect(() => {
		dispatch(fetchCategories());
		dispatch(fetchTypes());
		dispatch(fetchConditions());
		dispatch(fetchSizes());
	},[])
	
	useEffect(() => {
		if (selectedItems && selectedItems.length > 0) {
			const selectedItem = selectedItems[0];
			const { category, size, containerType } = selectedItem;
			const filteredContainer = {
				category,
				size,
				containerType
			};
			setFirstEditContainer(filteredContainer);
		}
	}, [selectedItems]);

	/** get data for table with pagination */
	const getData = (currentPageInner?: number) => {
		if (userInfo === undefined || userInfo === null) return;
		const userLocation = userInfo.location?.split(',')[0].toLowerCase();

		const userLocCode = countryList.find(
			(country: any) => country.label.toLowerCase() === userLocation
		) ?? { label: '', value: '' };
		setAgentCountry(userLocCode);

		let headers = undefined;
		const minValue = ((currentPageInner ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		let tempParam = param;
		if (userInfo?.role !== 'admin') {
			tempParam = {
				...param,
				country: userLocCode.value ?? ''
			};
		}
		if (userInfo?.role === 'admin' || (tempParam['country'] && tempParam['country'] !== '')) {
			axios
				.get('/api/container/laraContainers', {
					params: tempParam,
					headers,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					const rangeVal = response?.headers['content-range'];
					const rangeSplit = rangeVal.toString().split('/');
					const totalElements = parseInt(rangeSplit[1]);
					setTotalRecords(totalElements);
					setPageSize(10);
					setAwaitingConfirmationData(response.data);
					const newData = response.data;
					// Check if filtered data is empty
					if (newData.length === 0) {
						setAwaitingConfirmationData([]);
						setIsLoading(false);
						setTotalRecords(0);
					}
					const currentSelectedRows = selectedRows.get(currentPageInner ? currentPageInner : 1);
					const currentRowsState = currentSelectedRows?.map((row) => newData[row]);
					setContainersDeselected(currentRowsState);
				})
				.catch(() => {
					setAwaitingConfirmationData([]);
					setIsLoading(false);
					setTotalRecords(0);
				});
		} else {
			setAwaitingConfirmationData([]);
		}
	};

	// Debounce the API call with a delay of 1000 milliseconds
	const debouncedGetData = debounce(getData, 1000);
	useEffect(() => {
		debouncedGetData();		
		setCurrentPage(1);
		setSelectedItems([]);
		setSelectedRows(new Map<number, number[]>([]));
		setButtonDisabled(true);
		setButtonEditDisabled(true);
	}, [param]);
	
	useEffect(() => {
		const fetchData = async () => {
			if(awaitingConfirmationData){
				const transformedArray = await Promise.all(
					awaitingConfirmationData.map(async (item) => {
						if (item.images.length !== 0) {
							try {
							// Make an API call using Axios when item is not empty
							const response = await axios.get(`/api/download/${item.images[0].s3ImageName}`, {
								method: 'GET',
								responseType: 'blob'
							});
							// Return the API response data or any specific property you need
							const blob = new Blob([response.data]);
							const imageUrl = URL.createObjectURL(blob);
							return imageUrl;
							} catch (error) {
							console.error('Error fetching data:', error);
							return null; // Return null or handle error case appropriately
							}
						} else {
							return null; // Return null for empty items
						}
						})
				);
				setContainerImage(transformedArray);
			}
	
		};
	
		fetchData();
	}, [awaitingConfirmationData]);

	useEffect(() => {
		setCurrentPage(1);
		setSelectedItems([]);
		setSelectedRows(new Map<number, number[]>([]));
		setButtonDisabled(true);
		setButtonEditDisabled(true);
		setParam({
			...param,
			sortBy: sorting.sortBy,
			sortOrder: sorting.sortOrder,
			price: priceRange
		});
	}, [sorting, priceRange]);

	const paramsContainersProducts = {
		agentUid: userInfo.ccgId,
		containers: selectedItems
	};
	
	/** confirm selected records */
	const handleConfirmContainer = () => {
		let headers: { range: string } | undefined = undefined;
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		if (confirmError) {
			setOpenError(true);
		} else {
			setProgressBar(true);
			axios
				.put('/api/container/laraContainers/products', paramsContainersProducts)
				.then((response) => {
					if (response.status === 200) {
						setAwaitingConfirmationData(response?.data);
						setProgressBar(false);
						setSelectedRows(new Map<number, number[]>([]));
						setSelectedItems([]);
						setCurrentPage(1);
						setButtonDisabled(true);
						setButtonEditDisabled(true);
						getData();
					}
				})
				.catch((error) => {
					setProgressBar(false);
					conditionsChecker();
					setOpenError(true);
					setMessageType('error');
					setMessageText(error.response.data.description);
				});
		}
	};

	const handleCountContainer = () => {
		console.log(selectedItems.length);
		if (selectedItems.length > 50) {
			setMessageType('error');
			setMessageText('The maximum number of selected containers should not exceed 50');
			setOpenError(true);
		} else {
			setOpenEdit(true);
		}
	};

	const array: any[] = [];
	const conditionsChecker = useCallback(() => {
		const firstContainer = selectedItems[0];
		if (selectedItems.length === 0) {
			setButtonDisabled(true);
			setButtonEditDisabled(true);
			return;
		}
	
		let allPricesZeroOrNone = true;
		let allContainersMatch = true;
	
		selectedItems.forEach(item => {
			if (Number(item.price) === 0 && item.condition === 'none') {
				allPricesZeroOrNone = false;
			}
	
			if (item.containerType !== firstContainer.containerType ||
				item.category !== firstContainer.category ||
				item.size !== firstContainer.size) {
				allContainersMatch = false;
			}
		});
	
		setButtonDisabled(!allPricesZeroOrNone);
		setButtonEditDisabled(!(selectedItems.length > 1 && allContainersMatch));
	}, [selectedItems]);
	
	useEffect(() => {
		conditionsChecker();
	}, [conditionsChecker]);
	

	/** mui datatable options */
	const options = {
		selectableRows: 'multiple' as SelectableRows,
		onRowSelectionChange(
			currentRowSelected: any,
			allRowsSelected: any[],
			rowsSelected?: any[]
		) {
			// Create a copy of the selected rows map to update
			const selectedRowsCopy = new Map(selectedRows);
			selectedRowsCopy.set(currentPage, rowsSelected || []);
		
			// Get all selected items across all pages
			const allSelectedRows = Array.from(selectedRowsCopy.values()).flat();
			
			let updatedSelectedItems: any[] = [...selectedItems]; // Copy of selected items
		
			if (allRowsSelected.length === 0) {
				// Deselect all on the current page
				updatedSelectedItems = updatedSelectedItems.filter(
					(item) => !awaitingConfirmationData.some((row) => row.id === item.id)
				);
			} else if (allRowsSelected.length === awaitingConfirmationData.length) {
				// Select all on the current page
				const newSelections = awaitingConfirmationData.filter(
					(row) => !updatedSelectedItems.some((item) => item.id === row.id)
				);
				updatedSelectedItems = [...updatedSelectedItems, ...newSelections];
			} else {
				// Handle individual selection/deselection
				const selectedItem = awaitingConfirmationData[currentRowSelected[0].index];
				if (updatedSelectedItems.some((item) => item.id === selectedItem.id)) {
					// Deselect the item
					updatedSelectedItems = updatedSelectedItems.filter(
						(item) => item.id !== selectedItem.id
					);
				} else {
					// Select the item
					updatedSelectedItems = [...updatedSelectedItems, selectedItem];
				}
			}
		
			setSelectedRows(selectedRowsCopy); // Update the selected rows state
			setSelectedItems(updatedSelectedItems); // Update the global selected items
		},
		rowsSelected:
			(selectedRows && selectedRows.size > 0 && selectedRows?.get(currentPage)) || [],
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No Containers match your search'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		rowHover: false,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			getData(currentPage + 1);
		},
		customToolbar: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button
						disabled={buttonDisabled}
						variant="outlined"
						onClick={() => {
							handleConfirmContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
					>
						Confirm
					</Button>
					<Button
						disabled={buttonEditDisabled}
						variant="outlined"
						onClick={() => {
							handleCountContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
					>
						Edit Selected
					</Button>
					<Button onClick={getExport} sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		customToolbarSelect: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button
						disabled={buttonDisabled}
						variant="outlined"
						onClick={() => {
							handleConfirmContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
						style={{ marginRight: '10px !important' }}
					>
						Confirm
					</Button>
					<Button
						disabled={buttonEditDisabled}
						variant="outlined"
						onClick={() => {
							handleCountContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
						style={{ marginRight: '10px !important' }}
					>
						Edit Selected
					</Button>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false
	};
	const handleSelectChange = (event: SelectChangeEvent) => {
		setCurrentPage(1);
		const name = event.target.name;
		const filterTemp = { ...param, [name]: [...event.target.value] };
		setParam(filterTemp);
	};

	const handleInputTextChange = (event: any) => {
		setCurrentPage(1);

		const name = event.target.name;
		const filterTemp = { ...param, [name]: event.target.value };
		setParam(filterTemp);
	};

	const getExport = () => {
		if (userInfo === undefined || userInfo === null) return;
		const userLocation = userInfo.location?.split(',')[0].toLowerCase();

		const userLocCode = countryList.find(
			(country: any) => country.label.toLowerCase() === userLocation
		) ?? { label: '', value: '' };
		setAgentCountry(userLocCode);
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		setIsLoading(true);
		let tempParam = param;
		if (userInfo?.role !== 'admin') {
			tempParam = {
				...param,
				country: userLocCode.value ?? '',
				range: `${minValue}-${maxValue}`
			};
		}
		axios .get('/api/container/laraContainers/export', {
			method: 'GET',
			responseType: 'blob',
			params: tempParam,
			paramsSerializer: {
				serialize: (params) => {
					return qs.stringify(params, { arrayFormat: 'comma', encode: false });
				}
			}
		})
		.then((response) => {
			setIsLoading(false);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'awaiting-confirmation-table-export.xlsx');
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => {
			setIsLoading(false);
			console.log(err)
		});
	}

	/** clear all filters */
	const clearFilters = () => {
		setCurrentPage(1);
		setSelectedItems([]);
		setSelectedRows(new Map<number, number[]>([]));
		setButtonDisabled(true);
		setButtonEditDisabled(true);
		setPriceRange([0, 10000]);
		setParam(paramInitialState);
	};

	/** when click on edit button get the row data by id to be displayed in edit popup */
	const onEditRow = (rowMetaData: any) => {
		setOpen(true);
		const container = awaitingConfirmationData[rowMetaData.rowIndex] ?? null;

		setRowDataById(container);
	};

	return (
		<>
			{openEdit && (
				<AgentEditMultiAcModal
					open={openEdit}
					setOpen={setOpenEdit}
					selected={selectedItems}
					firstEditContainer={firstEditContainer}
					setFirstEditContainer={setFirstEditContainer}
					setAwaitingConfirmationData={setAwaitingConfirmationData}
					setIsLoading={setIsLoading}
					setCurrentPage={setCurrentPage}
					param={param}
					setConfirmError={setConfirmError}
					getData={getData}
					setSelectedRows={setSelectedRows}
					setSelectedItems={setSelectedItems}
				/>
			)}
			{open && (
				<AgentEditAcModal
					open={open}
					setOpen={setOpen}
					rowDataById={rowDataById}
					setRowDataById={setRowDataById}
					setAwaitingConfirmationData={setAwaitingConfirmationData}
					setIsLoading={setIsLoading}
					setCurrentPage={setCurrentPage}
					param={param}
					setConfirmError={setConfirmError}
					getData={getData}
					setSelectedRows={setSelectedRows}
					setSelectedItems={setSelectedItems}
				/>
			)}
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<AgentLayout>
				<div className="agentConfirmation">
					{/* Title */}
					<div className="agentConfirmation-top">
						<p>Manage Containers Awaiting Confirmation</p>
					</div>
					{/* Table */}
					<div className="agentConfirmation-bottom">
						{/* Bottom */}
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title=""
								data={awaitingConfirmationData ? awaitingConfirmationData : []}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</AgentLayout>
		</>
	);
};

export default AgentACContainers;

