 
import { Backdrop, Button, CircularProgress } from '@mui/material';
import Popover from '@mui/material/Popover';
import { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import MUIDataTable, {
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, setFilter } from '../../../redux/features/filter';
import './Products.css';
import {
	emptyHeaderTemplate,
	getMuiTheme,
	inputTextHeaderTemplate,
	multipleInputHeaderTemplate,
	noSelectHeaderTemplate,
	rangeHeaderTemplate,
	selectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import {
	countryOptions,
} from '../../../shared/constants/optionsConstants';
import { Product } from '../../../shared/models/product';
import colors from '../../../shared/styles/_variables.module.scss';
import BuyerLayout from '../BuyerDashboard/BuyerLayout';
import CartButton from '../Checkout/CartButton';
import AlertCmpt from '../Util/AlertCmpt';
import ProductDetailsSummary from './ProductDetailsSummary';
import { fetchCategories } from '../../../redux/features/category';
import { fetchConditions } from '../../../redux/features/condition';
import { fetchTypes } from '../../../redux/features/type';
import { fetchSizes } from '../../../redux/features/size';

const Products = () => {
	const dispatch = useDispatch();
	const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
	const filter = useSelector((state: any) => state.filter.value);
	const currency = useSelector((state: any) => state.currency.value);
	const sizesState = useSelector((state: any) => state.sizes);
	const loggedInUser = useSelector((state: any) => state.loggedInUser.value);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [sorting, setSorting] = useState({ sortBy: 'country', sortOrder: 'asc' });
	const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
	const [openPopOver, setOpenPopOver] = useState(false);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [productDetails, setProductDetails] = useState<Product>(new Product());
	const [containerImage, setContainerImage] = useState<any>([]);
	const conditionsState = useSelector((state: any) => state.conditions);
	const categoriesState = useSelector((state: any) => state.categories);
	const typesState = useSelector((state: any) => state.types);
	const isUserLoggedIn = loggedInUser !== null;

	const conditionOptions = conditionsState.conditions.map((item:any) => ({
		label: item.condition,
		value: item.condition
	}));

	const categoryOptions = categoriesState.categories.map((item:any) => ({
		label: item.category,
		value: item.category
	}));
	
	const typeOptions = typesState.types.map((item:any) => ({
		label: item.containerType,
		value: item.containerType
	}));
	const sizeOptions = sizesState.sizes.map((item:any) => ({
		label: item.size + 'ft',
		value: item.size
	}));
	useEffect(() => {
		dispatch(fetchCategories());
		dispatch(fetchTypes());
		dispatch(fetchConditions());
		dispatch(fetchSizes());
	},[])

	const handleClose = () => {
		setOpenPopOver(false);
	};

	useEffect(() => {
		dispatch(
			setFilter({
				...filter,
				sortBy: sorting.sortBy,
				sortOrder: sorting.sortOrder,
				price: priceRange
			})
		);
	}, [sorting,priceRange]);

	useEffect(() => {
		if (typeof loggedInUser !== 'undefined') getData(filter);
	}, [currentPage > 1, filter, currency, loggedInUser]);

	useEffect(() => {
		const fetchData = async () => {
		  const transformedArray = await Promise.all(
			filteredProducts.map(async (item) => {
				if (item?.defaultImage !== null) {
					try {
					// Make an API call using Axios when item is not empty
					const response = await axios.get(`/api/download/${item?.defaultImage?.s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					});
					// Return the API response data or any specific property you need
					const blob = new Blob([response.data]);
					const imageUrl = URL.createObjectURL(blob);
					return imageUrl;
					} catch (error) {
					console.error('Error fetching data:', error);
					return null; // Return null or handle error case appropriately
					}
				} else {
					return null; // Return null for empty items
				}
				})
		  );
	
		  setContainerImage(transformedArray);
		};
	
		fetchData();
	}, [filteredProducts]);
	console.log('loggedInUser', loggedInUser);
	console.log('isUserLoggedIn', isUserLoggedIn);

	const getData = (filterTemp: any, currentPageIndex?: number) => {
		let headers = undefined;
		const minValue = ((currentPageIndex ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		// If loggedInUser is null, add country filter with value '_ALL_'
		if (loggedInUser === null) {
			const currentCountryFilter = filterTemp.country;
			// Ensure the 'country' filter is an array and includes '_ALL_'
			if (Array.isArray(currentCountryFilter)) {
				filterTemp = { ...filterTemp, country: [...currentCountryFilter, '_ALL_'] };
			} else {
				filterTemp = { ...filterTemp, country: '_ALL_' };
			}
		}

		axios
			.get('/api/container/products', {
				params: filterTemp,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				setTotalRecords(totalElements);
				setPageSize(10);
				setFilteredProducts(response.data);
				setIsLoading(false);
			})
			.catch(() => {
				setFilteredProducts([]);
				setIsLoading(false);
				setTotalRecords(0);
			});
	};

	const handleRowClick = (rowData: any) => {
		setProductDetails(filteredProducts.find((x) => x.id === rowData[0]) ?? new Product());
		setOpenPopOver(true);
	};

	const options = {
		selectableRows: 'none' as SelectableRows,
		customToolbar: () => {
			return (
				<div>
					{/* <Button sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button> */}
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		onRowClick: loggedInUser
		? (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => handleRowClick(rowData)
		: () => {},
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No Products match your search'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			if (typeof loggedInUser !== 'undefined') getData(filter, currentPage + 1);
		},
		// Set row props based on the loggedInUser condition
		setRowProps: () => {
			return {
				className: !loggedInUser ? 'no-hover' : '', // Add 'no-hover' class if loggedInUser is null
			};
		}
	};

	const handleSelectChange = (event: SelectChangeEvent) => {
		const name = event.target.name;
		const filterTemp = { ...filter, [name]: [...event.target.value] };
		dispatch(setFilter(filterTemp));
		setCurrentPage(1);
		if (typeof loggedInUser !== 'undefined') getData(filterTemp);
	};

	const handleInputTextChange = (event: any) => {
		const name = event.target.name;
		const filterTemp = { ...filter, [name]: event.target.value };
		dispatch(setFilter(filterTemp));
		setCurrentPage(1);
		if (typeof loggedInUser !== 'undefined') getData(filterTemp, 1);
	};

	const clearFilters = () => {
		setPriceRange([0, 10000]);

		dispatch(resetState(filter));
		setCurrentPage(1);
		if (typeof loggedInUser !== 'undefined') getData(filter, 1);
		options.count = filteredProducts.length;
	};
	

	const columnDefs = [
		{
			label: 'productId',
			name: 'id',
			options: {
				display: false
			}
		},
		{
			label: 'starting_at_price',
			name: 'startingAtPrice',
			options: {
				display: false
			}
		},
		{
			label: 'Product Images',
			name: 'defaultImage',
			options: {
				customBodyRenderLite: (index: any) => {
					let imageSource = '/media/container-logo.png';
					const containerS3Image = containerImage?.at(index);
					if(containerS3Image !== null){
						return <img src={containerS3Image} />
					}
				
						return <img src={imageSource} />;
				},
				/* customBodyRender: () => {
					return <img src="/media/container-logo.png" />;
				}, */
				customHeadRender: () => emptyHeaderTemplate()
			}
		},
		{
			label: 'Country',
			name: 'country',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						countryOptions,
						filter,
						handleSelectChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'City',
			name: 'city',
			options: {
				customBodyRender: (value: any, tableMeta: any) => {
					return value?.charAt(0).toUpperCase() + value?.slice(1);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						filter,
						handleInputTextChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Pool Full Name',
			name: 'depotName',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value?.charAt(0).toUpperCase() + value?.slice(1);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						filter,
						handleInputTextChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Category',
			name: 'category',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value?.charAt(0).toUpperCase() + value?.slice(1);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						categoryOptions,
						filter,
						handleSelectChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Size',
			name: 'size',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[7] ? tableMeta.rowData[7] + 'ft' : '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						sizeOptions,
						filter,
						handleSelectChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Type',
			name: 'productType',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						typeOptions,
						filter,
						handleSelectChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Condition',
			name: 'condition',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value?.charAt(0).toUpperCase() + value?.slice(1);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						conditionOptions,
						filter,
						handleSelectChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Price',
			name: 'price',
			options: {
				display: isUserLoggedIn,
				customBodyRenderLite: (dataIndex: any) => {
					let priceAfterCurrencyChange = null;
					if (!currency) {
						priceAfterCurrencyChange = filteredProducts
							.at(dataIndex)
							?.prices?.find((price: any) => price.currency === 'USD');
					} else {
						priceAfterCurrencyChange = filteredProducts
							.at(dataIndex)
							?.prices?.find((price: any) => price.currency === currency);
					}
				
					const currencyDisplay = priceAfterCurrencyChange?.currency ? priceAfterCurrencyChange.currency + ' ' : '';
					const priceDisplay = Math.round(priceAfterCurrencyChange?.price ?? 0);
				
					return currencyDisplay + priceDisplay;
				},
				...(isUserLoggedIn && {customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
						multipleInputHeaderTemplate(
							columnMeta.label || '',
							columnMeta.name,
							filter,
							handleInputTextChange,
							sorting,
							setSorting
						)
				})
			}
		},
		{
			label: 'In Stock',
			name: 'inStock',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Add To Cart',
			name: 'addToCart',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const product = filteredProducts.find((x) => x.id === tableMeta?.rowData[0]);
					return (
						<CartButton
							product={product}
							setOpen={setOpen}
							setMessageText={setMessageText}
							setMessageType={setMessageType}
							hideLabel={true}
						/>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || ''),
				display: loggedInUser?.role === 'buyer' || !loggedInUser
			}
		},
		{
			label: 'currency',
			name: 'currency',
			options: {
				display: false
			}
		}
	];

	return (
		<BuyerLayout>
			<ThemeProvider
				theme={createTheme({
					components: {
						MuiPopover: {
							styleOverrides: {
								paper: {
									borderRadius: '20px',
									border: '1px solid #04246a',
									padding: '20px'
								}
							}
						}
					}
				})}
			>
				<Popover
					open={openPopOver}
					onClose={handleClose}
					anchorReference={'none'}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ProductDetailsSummary
						productDetails={productDetails}
						handleClose={handleClose}
					/>
				</Popover>
			</ThemeProvider>
			<main style={{ marginTop: '60px', filter: openPopOver ? 'blur(3px)' : 'none' }}>{/* 
				{isLoading && (
					<Backdrop
						sx={{
							color: colors.colorFillPrimaryBlue,
							zIndex: (theme) => theme.zIndex.drawer + 1
						}}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)} */}
				<div>
					<AlertCmpt
						open={open}
						setOpen={setOpen}
						messageType={messageType}
						messageText={messageText}
					/>

					<ThemeProvider theme={getMuiTheme()}>
						<p style={{
							fontWeight: 'bold', 
							width: '100%', 
							textAlign: 'center', 
							paddingTop: '10px'}}>
								Please reach out to your local CMA CGM Agent for any inquiries 
								(<a 
								href='https://www.cma-cgm.com/local-offices'
								target="_blank">https://www.cma-cgm.com/local-offices
								</a>)
						</p>
						{loggedInUser !== undefined && (<MUIDataTable
							title={'Products'}
							data={filteredProducts}
							columns={columnDefs}
							options={options}
						/>)}
					</ThemeProvider>
				</div>
			</main>
		</BuyerLayout>
	);
};

export default Products;
